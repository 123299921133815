<template>
  <v-card class="create-shop mt-7">
    <!-- form header -->
    <CardHeader>
      <template v-slot:left>
        <slot name="left"></slot>
      </template>
      <template v-slot:right>
        <slot name="right"></slot>
      </template>
    </CardHeader>
    <!-- main form -->

    <validation-observer ref="observer">
      <v-form ref="form" @submit.prevent="submit">
        <!-- the outer spacing -->
        <v-row no-gutters justify="space-around" class="main-form pt-7 pb-12">
          <!-- left side -->
          <v-col cols="10" xl="5" md="5" lg="5">
            <!-- store name -->

            <InputFormElement
              :important="true"
              labelWidth="2"
              :label="$t('page_new_store_name')"
            >
              <!-- name -->
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required:店舗名"
              >
                <v-text-field
                  dense
                  height="27px"
                  flat
                  outlined
                  full-width
                  placeholder="店舗名"
                  :error-messages="errors"
                  v-model="fields.store_name"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
            <!-- phone number -->
            <InputFormElement
              labelWidth="2"
              :important="true"
              :label="$t('page_new_store_telephone_number')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="phone"
                rules="required:電話番号|enter_half_width_numbers_hyphens"
              >
                <v-text-field
                  dense
                  height="27px"
                  flat
                  outlined
                  full-width
                  placeholder="電話番号"
                  :error-messages="errors"
                  v-model="fields.phone_number"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
            <!-- email -->
            <InputFormElement
              labelWidth="2"
              :label="$t('page_new_store_email_address')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="email_address"
                rules="email"
              >
                <v-text-field
                  dense
                  height="27px"
                  flat
                  outlined
                  :error-messages="errors"
                  placeholder="メールアドレス"
                  full-width
                  v-model="fields.mail_address"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
            <!-- representative -->
            <InputFormElement
              labelWidth="2"
              :label="$t('page_new_store_representative')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="representative"
                rules=""
              >
                <v-text-field
                  dense
                  height="27px"
                  flat
                  outlined
                  :error-messages="errors"
                  placeholder="代表者"
                  full-width
                  v-model="fields.representative"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
          </v-col>
          <v-col cols="10" xl="5" md="5" lg="5">
            <!-- postal code -->
            <InputFormElement
              labelWidth="3"
              :important="true"
              :label="$t('page_new_store_postal_codes')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="postal_code"
                rules="required:郵便番号"
              >
                <v-text-field
                  dense
                  height="27px"
                  flat
                  outlined
                  full-width
                  placeholder="郵便番号"
                  :error-messages="errors"
                  v-model="fields.postal_code"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
            <!-- prefectures -->
            <InputFormElement
              labelWidth="3"
              :label="$t('page_new_store_prefectures')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="prefecture"
                rules=""
              >
                <v-select
                  :items="listPrefectures"
                  item-text="name"
                  item-value="id"
                  class="error-outline"
                  dense
                  outlined
                  :error-messages="errors"
                  v-model="fields.prefectures"
                ></v-select>
              </validation-provider>
            </InputFormElement>
            <!-- city Address -->
            <InputFormElement
              labelWidth="3"
              :label="$t('page_new_store_city_address')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="city_address"
                rules=""
              >
                <v-text-field
                  dense
                  outlined
                  :error-messages="errors"
                  v-model="fields.city_address"
                  placeholder="市区町村・番地"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
            <!-- bulding number room number -->
            <InputFormElement
              labelWidth="3"
              :label="$t('page_new_store_room_number')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="room_number"
                rules=""
              >
                <v-text-field
                  dense
                  outlined
                  placeholder="建物名・部屋番号等"
                  :error-messages="errors"
                  v-model="fields.building_name_room_number"
                ></v-text-field>
              </validation-provider>
            </InputFormElement>
          </v-col>
        </v-row>
        <v-row no-gutters justify="space-around" class="pt-11">
          <!-- left side -->
          <v-col cols="10" xl="5" md="5" lg="5">
            <!-- company form -->
            <InputFormElement
              :label="$t('page_new_store_company_form')"
              labelWidth="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="company_from"
                rules=""
              >
                <v-select
                  :items="listCompanyForm"
                  item-text="value"
                  item-value="id"
                  dense
                  :error-messages="errors"
                  outlined
                  v-model="fields.company_type"
                ></v-select>
              </validation-provider>
            </InputFormElement>
            <!-- fiscal year start month -->
            <InputFormElement
              class="my-2"
              :important="true"
              :label="$t('page_new_store_fiscal_year_start_month')"
              labelWidth="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="fiscal_year"
                rules="required:事業年度の開始月"
              >
                <v-select
                  :items="listFiscalYearStartMonth"
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                  :error-messages="errors"
                  v-model="fields.start_of_fiscal_year"
                ></v-select>
              </validation-provider>
            </InputFormElement>
          </v-col>
          <v-col cols="10" xl="5" md="5" lg="5">
            <InputFormElement
              labelWidth="3"
              :important="true"
              :label="$t('page_new_store_registeration_date')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="registration_date"
                rules="required:登記日"
              >
                <DatePicker type="month" v-model="fields.registration_date">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-on="on"
                      v-bind="attrs"
                      dense
                      :error-messages="errors"
                      outlined
                      :placeholder="
                        $t('page_new_store_registeration_date_placeholder')
                      "
                      v-model="fields.registration_date"
                    ></v-text-field>
                  </template>
                </DatePicker>
              </validation-provider>
            </InputFormElement>
            <!-- employees number -->
            <InputFormElement
              labelWidth="3"
              :label="$t('page_new_store_number_of_employees')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="number_of_employees"
                rules=""
              >
                <v-select
                  :items="listNumberOfEmployees"
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                  :error-messages="errors"
                  v-model="fields.number_of_employees"
                ></v-select>
              </validation-provider>
            </InputFormElement>
          </v-col>
        </v-row>
        <!-- submit buttons -->
        <v-row no-gutters class="pb-9 pt-11" align="center">
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-center text-light-grey">
            <v-btn
              type="submit"
              class="mx-2"
              width="84px"
              height="24px"
              color="primary"
              >{{ $t("page_new_store_register") }}</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import InputFormElement from "@/components/admin/partials/Shops/partials/InputFormElement";
import CardHeader from "@/components/admin/ui/CardHeader";
import DatePicker from "@/components/admin/ui/DatePicker";
export default {
  components: {
    InputFormElement,
    CardHeader,
    DatePicker
  },
  data() {
    return {
      loading: false,
      fields: {
        store_name: "",
        mail_address: "",
        phone_number: "",
        representative: "",
        postal_code: "",
        prefectures: "",
        city_address: "",
        building_name_room_number: "",
        company_type: "",
        start_of_fiscal_year: "",
        number_of_employees: "",
        registration_date: ""
      },
      listFiscalYearStartMonth: [
        { id: "1", value: "1月" },
        { id: "2", value: "2月" },
        { id: "3", value: "3月" },
        { id: "4", value: "4月" },
        { id: "5", value: "5月" },
        { id: "6", value: "6月" },
        { id: "7", value: "7月" },
        { id: "8", value: "8月" },
        { id: "9", value: "9月" },
        { id: "10", value: "10月" },
        { id: "11", value: "11月" },
        { id: "12", value: "12月" }
      ]
    };
  },
  computed: {
    listPrefectures() {
      return this.$store.getters.getPrefectures;
    },
    listNumberOfEmployees() {
      return this.$store.getters.getMasterData?.number_of_employees;
    },
    listCompanyForm() {
      return this.$store.getters.getMasterData?.company_type;
    }
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }
        let data = {};
        for (let field in this.fields) {
          data[field] = this.fields[field];
        }

        this.loading = true;
        this.$store
          .dispatch("SHOP_REGISTER", data)
          .then(response => {
            if (response.status === 200) {
              this.loading = false;
              this.$store.dispatch("MASTER_GET_ALL").then(() => {
                this.$router.push({ name: "Shops" });
              });
            }
          })
          .finally(() => (this.loading = false));
      });
    },
    currentDate() {
      const current = new Date();
      let year = current.getFullYear();
      let month = (current.getMonth() + 1).toString().padStart(2, "0");
      let day = current
        .getDate()
        .toString()
        .padStart(2, "0");
      const date = `${year}-${month}-${day}`;
      return date;
    }
  }
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
