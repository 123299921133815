<template>
  <ShopCreate>
    <template slot="left">
      <p class="font-18px font-weight-regular pa-5 mb-0">
        {{ $t("page_new_store_title") }}
      </p>
    </template>
    <template slot="right">
      <div
        class="d-flex justify-space-between align-center mr-8 form-heading-back mouse-pointer right-title"
        @click="$router.go(-1)"
      >
        <v-icon>$goBack</v-icon>
        <p class="mb-0">{{ $t("go_back") }}</p>
      </div>
    </template>
  </ShopCreate>
</template>
<script>
import ShopCreate from "@/components/admin/partials/Shops/ShopCreate";
export default {
  components: {
    ShopCreate
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" src="./global.scss" scoped></style>