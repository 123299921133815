var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "create-shop mt-7"
  }, [_c('CardHeader', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function fn() {
        return [_vm._t("left")];
      },
      proxy: true
    }, {
      key: "right",
      fn: function fn() {
        return [_vm._t("right")];
      },
      proxy: true
    }], null, true)
  }), _c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-row', {
    staticClass: "main-form pt-7 pb-12",
    attrs: {
      "no-gutters": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "5",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "important": true,
      "labelWidth": "2",
      "label": _vm.$t('page_new_store_name')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required:店舗名"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "full-width": "",
            "placeholder": "店舗名",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.store_name,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "store_name", $$v);
            },
            expression: "fields.store_name"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "2",
      "important": true,
      "label": _vm.$t('page_new_store_telephone_number')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "phone",
      "rules": "required:電話番号|enter_half_width_numbers_hyphens"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "full-width": "",
            "placeholder": "電話番号",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.phone_number,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "phone_number", $$v);
            },
            expression: "fields.phone_number"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "2",
      "label": _vm.$t('page_new_store_email_address')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email_address",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "error-messages": errors,
            "placeholder": "メールアドレス",
            "full-width": ""
          },
          model: {
            value: _vm.fields.mail_address,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "mail_address", $$v);
            },
            expression: "fields.mail_address"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "2",
      "label": _vm.$t('page_new_store_representative')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "representative",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "error-messages": errors,
            "placeholder": "代表者",
            "full-width": ""
          },
          model: {
            value: _vm.fields.representative,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "representative", $$v);
            },
            expression: "fields.representative"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "5",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "important": true,
      "label": _vm.$t('page_new_store_postal_codes')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "postal_code",
      "rules": "required:郵便番号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "full-width": "",
            "placeholder": "郵便番号",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.postal_code,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "postal_code", $$v);
            },
            expression: "fields.postal_code"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_prefectures')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "prefecture",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "error-outline",
          attrs: {
            "items": _vm.listPrefectures,
            "item-text": "name",
            "item-value": "id",
            "dense": "",
            "outlined": "",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.prefectures,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "prefectures", $$v);
            },
            expression: "fields.prefectures"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_city_address')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "city_address",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "outlined": "",
            "error-messages": errors,
            "placeholder": "市区町村・番地"
          },
          model: {
            value: _vm.fields.city_address,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "city_address", $$v);
            },
            expression: "fields.city_address"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_room_number')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "room_number",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "建物名・部屋番号等",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.building_name_room_number,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "building_name_room_number", $$v);
            },
            expression: "fields.building_name_room_number"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "pt-11",
    attrs: {
      "no-gutters": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "5",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "label": _vm.$t('page_new_store_company_form'),
      "labelWidth": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "company_from",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "items": _vm.listCompanyForm,
            "item-text": "value",
            "item-value": "id",
            "dense": "",
            "error-messages": errors,
            "outlined": ""
          },
          model: {
            value: _vm.fields.company_type,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "company_type", $$v);
            },
            expression: "fields.company_type"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    staticClass: "my-2",
    attrs: {
      "important": true,
      "label": _vm.$t('page_new_store_fiscal_year_start_month'),
      "labelWidth": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "fiscal_year",
      "rules": "required:事業年度の開始月"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          attrs: {
            "items": _vm.listFiscalYearStartMonth,
            "item-text": "value",
            "item-value": "id",
            "dense": "",
            "outlined": "",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.start_of_fiscal_year,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "start_of_fiscal_year", $$v);
            },
            expression: "fields.start_of_fiscal_year"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "5",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "important": true,
      "label": _vm.$t('page_new_store_registeration_date')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "registration_date",
      "rules": "required:登記日"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('DatePicker', {
          attrs: {
            "type": "month"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref12) {
              var on = _ref12.on,
                  attrs = _ref12.attrs;
              return [_c('v-text-field', _vm._g(_vm._b({
                attrs: {
                  "dense": "",
                  "error-messages": errors,
                  "outlined": "",
                  "placeholder": _vm.$t('page_new_store_registeration_date_placeholder')
                },
                model: {
                  value: _vm.fields.registration_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.fields, "registration_date", $$v);
                  },
                  expression: "fields.registration_date"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true),
          model: {
            value: _vm.fields.registration_date,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "registration_date", $$v);
            },
            expression: "fields.registration_date"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_number_of_employees')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "number_of_employees",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "items": _vm.listNumberOfEmployees,
            "item-text": "value",
            "item-value": "id",
            "dense": "",
            "outlined": "",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.number_of_employees,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "number_of_employees", $$v);
            },
            expression: "fields.number_of_employees"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "pb-9 pt-11",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "d-flex justify-center text-light-grey"
  }, [_c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "type": "submit",
      "width": "84px",
      "height": "24px",
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("page_new_store_register")))])], 1), _c('v-spacer')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }