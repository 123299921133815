var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pr-2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.useCompute ? _vm.computedLabelWidth : _vm.labelWidth,
      "align-self": "baseline"
    }
  }, [_c('div', {
    staticClass: "font-12px d-flex justify-end label"
  }, [_vm._v(_vm._s(_vm.label))])]), _vm.labelInputSpace ? _c('v-col', {
    attrs: {
      "cols": "1",
      "align-self": "baseline"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center",
    style: {
      visibility: _vm.important ? 'visible' : 'hidden'
    }
  }, [_c('div', {
    staticClass: "the-red-pills d-flex align-center justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("required")) + " ")])])]) : _vm._e(), _c('v-col', {
    attrs: {
      "align-self": "baseline"
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }