var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ShopCreate', [_c('template', {
    slot: "left"
  }, [_c('p', {
    staticClass: "font-18px font-weight-regular pa-5 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_new_store_title")) + " ")])]), _c('template', {
    slot: "right"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center mr-8 form-heading-back mouse-pointer right-title",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', [_vm._v("$goBack")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("go_back")))])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }