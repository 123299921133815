<template>
  <!-- responsive cols = 2 -->
  <v-row dense class="pr-2">
    <v-col
      :cols="useCompute ? computedLabelWidth : labelWidth"
      align-self="baseline"
      ><div class="font-12px d-flex justify-end label">{{ label }}</div></v-col
    >
    <v-col cols="1" v-if="labelInputSpace" align-self="baseline">
      <div
        class="d-flex justify-center"
        :style="{ visibility: important ? 'visible' : 'hidden' }"
      >
        <div class="the-red-pills d-flex align-center justify-center">
          {{ $t("required") }}
        </div>
      </div>
    </v-col>
    <v-col align-self="baseline">
      <slot></slot>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    labelWidth: {
      type: String,
      required: false,
      default: "1"
    },
    important: {
      type: Boolean,
      required: false,
      default: false
    },
    labelInputSpace: {
      type: Boolean,
      required: false,
      default: true
    },
    useCompute: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    computedLabelWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "3";
        case "sm":
          return "3";
        case "md":
          return "3";

        default:
          return this.labelWidth;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.visible {
  visibility: hidden !important;
}
.label {
  color: #9b9b9b;
}
.the-red-pills {
  width: 37px;
  height: 17px;
  font-size: 8px;
  color: white;
  background-color: #d65858;
  border-radius: 2px;
}
</style>
